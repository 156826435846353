<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md3 class="mt-4">
            <v-card elevation="0">
              <v-card-text>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs10>
                        <div class="pa-4">
                          <v-btn depressed block class="primary text-none">
                            New Message <v-icon>mdi-fountain-pen-tip</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs2>
                        <div class="mt-4">
                          <v-btn depressed class="background mr-3">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider class="mt-4 ml-3"></v-divider>
                  </v-flex>
                  <v-flex xs12>
                    <v-list>
                      <a>
                        <v-list-item class="accent mt-3 ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="white mr-3">
                              <v-icon class="primary--text">mdi-robot</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Bot
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 9:31 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Last message..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Jane Doe
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 7:05 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Okay Thanks..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-divider class="ml-3"></v-divider>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        James
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 6:59 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Is it possible to..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Mrs Wachira
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 6:17 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Olunga to to Arsenal Conf..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Charles
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 6:13 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Please Check the support Tick..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Henry Rotich
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 6:13 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        Good tip! I'll definetly tr..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                      <a>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-account</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Happy Momanyi
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 6:13 AM
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="
                                          black--text
                                          caption
                                          mt-3
                                          font-italic
                                        ">
                                        I've been going for tra..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout column class="mt-4 mx-1">
              <v-flex xs12>
                <v-card elevation="0">
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs10>
                        <v-list-item class="ml-2">
                          <v-list-item-icon>
                            <v-avatar size="45" class="accent mr-3">
                              <v-icon class="white--text">mdi-robot</v-icon>
                            </v-avatar>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        Bot
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        16/05/2022 9:31 AM
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-flex>
                      <v-flex xs2>
                        <div class="mt-4" align="right">
                          <v-btn depressed class="background mr-3">
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 class="mt-1">
                <div style="
                    margin: 4px, 4px;
                    padding: 4px;
                    width: auto;
                    height: 70vh;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    text-align: justify;
                  ">
                  <template v-for="(message, index) in messages">
                    <div :key="index" class="mx-2">
                      <v-flex xs12 class="mb-2">
                        <div v-if="message.sender == 2" align="left">
                          <div class="talk-bubble tri-right left-top">
                            <div class="talktext">
                              <p class="white--text"> {{ message.message }}</p>
                            </div>
                          </div>
                        </div>
                        <div v-else align="right">
                          <div class="reply-bubble tri-right btm-right">
                            <div class="talktext">
                              <p>{{ message.message }}</p>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </div>
                  </template>
                </div>


              </v-flex>
              <v-flex xs12>
                <v-card elevation="0">
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs10>
                        <v-text-field outlined class="mt-6 ml-3" placeholder="Send a message.." v-model="chatMessage" v-on:keyup.enter="sendMessage()">
                        </v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <div class="mt-8" align="right">
                          <v-btn depressed class="primary mr-3" @click="sendMessage()" :loading="loading">
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md3 class="mt-4">
            <v-card elevation="0">
              <v-card-text>
                <v-layout column>
                  <v-flex xs12>
                    <v-list>
                      <a>
                        <v-list-item class="mt-3 ml-2">
                          <v-list-item-icon>
                            <v-badge bordered bottom color="green accent-4" dot offset-x="19" offset-y="10">
                              <v-avatar size="45" class="primary mr-3">
                                <v-icon class="white--text">mdi-robot</v-icon>
                              </v-avatar>
                            </v-badge>

                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="white--text">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="black--text font-weight-black">
                                        FirstName LastName
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div class="black--text caption">
                                        Available
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </a>
                    </v-list>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider class="mr-3"></v-divider>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div><b>Personal Information</b></div>
                    <div>Phone Number: 0711 XXX XXX</div>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider class="mr-3 mt-5"></v-divider>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div><b>Notes</b></div>
                    <div>Notes help you to keep track of your conversation with your clients</div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    AuthPopUp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      loading: false,
      messages: [],
      chatData: {
        sender: 2,
        message: null
      },
      chatMessage: null
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["changeLoginCardState"]),
    sendMessage() {

      var obj = {};
      obj["sender"] = 2;
      obj["message"] = this.chatMessage;
      this.messages.push(obj);

      this.chatData.message = this.chatMessage
      this.chatData.sender = 2

      this.loading = true;
      apiCall({
        url: "",
        data: this.chatData,
        method: "POST",
      })
        .then((resp) => {
          this.chatMessage = null
          this.loading = false;
          console.log('resp is', resp)
          var reply = {};
          reply["sender"] = 1;
          reply["message"] = resp.answer;
          this.messages.push(reply);
        })
        .catch((error) => {
          this.message = "An error occurred, Kindly try again";
          this.loading = false;
          this.snackbar = true;
          this.alertType = "error";
        });
    }
  },
  computed: {
    ...mapGetters(["loginCardState"]),
  },
};
</script>
