<template>
  <div>
    <!-- <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{message}}</span>
		</v-snackbar> -->
    <v-dialog
      v-model="loginCardState"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card
        :loading="loading"
        class="elevation-1"
        max-width="700"
        color="primary"
      >
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-toolbar dark flat color="transparent">
          <v-btn icon dark @click="changeFormLogin" v-if="authKind != 'login'">
            <v-icon large color="white">mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="changeLoginCardState">
            <v-icon large color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-layout column>
            <v-flex xs12 class="mb-10">
                <v-layout row wrap>
                <v-flex xs12 md6>
                    <div align="center" class="mx-5">
                    <v-img class="mt-5" max-width="280" src="img/UNHCR.png"> </v-img>
                    </div>
                </v-flex>
                <v-flex xs12 md6>
                    <div align="center" class="mx-5 font-weight-black display-3 white--text mt-7">
                        School
                    </div>
                </v-flex>
                </v-layout>
            </v-flex>
            </v-layout>
        </v-card-text>
        
        <div v-if="authKind == 'login'">
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-card-text class="secondary">
                <div class="my-5 mx-10">
                    <v-alert
                        class="mx-2 mt-2"
                        v-if="snackbar"
                        dense
                        text
                        dismissible
                        :type="alertType"
                        >
                        {{ message }}
                        </v-alert>
                    <v-text-field
                        filled
                        v-model="email"
                        :rules="emailRules"
                        prepend-inner-icon="mdi-email-outline"
                        label="E-mail"
                        required
                        class="white login_field"
                    ></v-text-field>
                    <v-text-field
                        filled
                        v-model="password"
                        prepend-inner-icon="mdi-lock-outline"
                        :rules="inputRules"
                        label="Password"
                        type="password"
                        class="white login_field mt-3"
                    ></v-text-field>
                    <v-btn
                        depressed
                        block
                        large
                        color="primary"
                        :disabled="!valid"
                        @click="login"
                        :loading="loading"
                        class="text-none mt-3"
                    >
                        Login
                        <v-icon dark right>mdi-login</v-icon>
                    </v-btn>
                    <v-layout row wrap class="mt-5 mx-1">
                        <v-flex xs12 md6>
                            <v-btn
                                class="primary--text text-none"
                                text
                                block
                                @click="changeForm"
                                >Create an account</v-btn>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-btn
                                class="primary--text text-none"
                                text
                                block
                                @click="changeFormPassword"
                                >Forgot Password?</v-btn
                            >
                        </v-flex>
                    </v-layout>
                </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'create'">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text class="secondary">
                <div class="my-5 mx-10">
                    <v-text-field
                        filled
                        dense
                        v-model="userData.name"
                        :rules="inputRules"
                        label="Name*"
                        prepend-inner-icon="mdi-account-outline"
                        class="white text_field"
                    ></v-text-field>
                    <v-text-field
                        filled
                        dense
                        v-model="userData.email"
                        :rules="emailRules"
                        label="E-mail*"
                        prepend-inner-icon="mdi-email-outline"
                        required
                        class="white text_field mt-3"
                    ></v-text-field>
                    <v-text-field
                        filled
                        dense
                        v-model="userData.password"
                        :rules="passwordRules"
                        label="Password*"
                        type="password"
                        prepend-inner-icon="mdi-lock-outline"
                        class="white text_field mt-3"
                    ></v-text-field>
                    <v-text-field
                        filled
                        dense
                        v-model="confirmpassword"
                        :rules="passwordConfirmRules"
                        label="Confirm Password*"
                        type="password"
                        prepend-inner-icon="mdi-lock-outline"
                        class="white text_field mt-3"
                    ></v-text-field>
                    <v-btn
                        depressed
                        block
                        large
                        color="primary"
                        :disabled="!valid"
                        @click="create"
                        :loading="loading"
                        class="text-none mt-3"
                    >
                        Create
                        <v-icon dark right>mdi-account-plus-outline</v-icon>
                    </v-btn>
                </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'password'">
          <v-form ref="passwordForm" v-model="valid" lazy-validation>
            <v-card-text class="secondary">
                <div class="my-5 mx-10">
                    <v-text-field
                        filled
                        dense
                        v-model="userData.email"
                        :rules="emailRules"
                        prepend-inner-icon="mdi-email-outline"
                        label="E-mail"
                        required
                        class="white text_field"
                    ></v-text-field>
                    <v-btn
                        depressed
                        block
                        large
                        color="primary"
                        :disabled="!valid"
                        @click="changePassword"
                        :loading="loading"
                        class="text-none mt-3"
                    >
                        Send me a link..
                        <v-icon dark right>mdi-login</v-icon>
                    </v-btn>
                </div>
            </v-card-text>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { AUTH_REQUEST } from "@/store/actions/auth";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "AuthPopUp",

  data: () => {
    return {
      alertType: "",
      authKind: "login",
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      valid: true,

      confirmpassword: "",

      passwordConfirmRules: [(v) => !!v || " "],

      inputRules: [(v) => !!v || " "],

      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimum 8 characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
        (v) =>
          /([!@$%.])/.test(v) ||
          "Must have at least one special character [!@#$%.]",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      userData: {
        email: "",
        password: "",
        email: "",
      },
    };
  },

  methods: {
    ...mapActions(["changeLoginCardState"]),
    reset() {
      this.$refs.form.reset();
    },
    login: function () {
      console.log(this.$store);
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        const { email, password } = this;
        this.$store
          .dispatch(AUTH_REQUEST, { email, password })
          .then((response) => {
            this.$store.dispatch("changeLoginCardState");
            this.loading = false;
            this.$router.push("/redirector");
          })
          .catch((response) => {
            this.message = "Wrong email or password/Account is not activated.";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    changePassword() {
      if (this.$refs.passwordForm.validate()) {
        this.loading = true;
        apiCall({
          url: "/api/user/reset-password",
          data: this.userData,
          method: "POST",
        })
          .then((resp) => {
            this.message = "We've sent you a reset link via email.";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.authKind = "login";
            this.alertType = "success";
          })
          .catch((error) => {
            this.message = "Account does not exist";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        if (this.userData.password != this.confirmpassword) {
          this.message = "Passwords do not match";
          this.color = "error";
          this.snackbar = true;
          this.alertType = "error";
        } else {
          this.loading = true;
          apiCall({ url: "/api/register", data: this.userData, method: "POST" })
            .then((resp) => {
              this.message =
                "Account Created. We've sent you an activation link via email.";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.authKind = "login";
              this.alertType = "success";
            })
            .catch((error) => {
              this.message = "The email has already been taken.";
              this.loading = false;
              this.snackbar = true;
              this.alertType = "error";
            });
        }
      }
    },
    changeForm() {
      this.authKind = "create";
      this.snackbar = false;
    },
    changeFormLogin() {
      this.authKind = "login";
      this.snackbar = false;
    },
    changeFormPassword() {
      this.authKind = "password";
      this.snackbar = false;
    },
  },
  computed: {
    ...mapGetters(["loginCardState", "nationalities", "genders"]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
    }),
  },
};
</script>